import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "./admin-service";
import { bus } from "@/main";

import CommonDialog from "@/olp-components/common/common-dialog.vue";
import AddressComp from "@/olp-components/common/address-comp.vue";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      address:"defaultAddress",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      addCompanyCalled:false,
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      cartList: [],
      productList: [],
      search: "",
      cartId: "",
      products: [],
      showAddToCart: true,
      showDialog: false,
      showAddressComp:false,
      message: "",
      product: "",
      disableBusiness:false,
      selectedBusiness:"",
      businessList:[],
      bu_key:EncryptUtility.localStorageDecrypt("olpBusinessSelected"),
      addObj:{
        comp_id:0,
        first: "",
        last: "",
        email: "",
        company: "",
        phone1: "",
        phone2: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
      },
      addObjShip:{
        comp_id:0,
        first: "",
        last: "",
        email: "",
        company: "",
        phone1: "",
        phone2: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
      }

    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    
  },
  async mounted() {
    if(this.bu_key){
       this.getCartData(this.bu_key)
       let obj={
        user_id: this.userId,
        pass: 1,
        bu_key:this.bu_key,
        comp_key: 0,
        first: "",
        last: "",
        email: "",
        company: "",
        phone1: "",
        phone2: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
      }
      let addressObject={json:JSON.stringify(obj)}
      let defaultAddress= await adminService.postDefaultAddress("post",addressObject,false)
      console.log(defaultAddress)
      this.addObj=defaultAddress.map((x) => ({
        comp_id:x.comp_id,
        first: x.first,
        last: x.last,
        email: x.email,
        company: x.company,
        phone1: x.phone1,
        phone2: x.phone2,
        address1: x.address1,
        address2: x.address2,
        city:x.city,
        state: x.state,
        zip: x.zip,
        country: x.country
      }));
    }
  },
  methods: {
    async getCartData(business) {
      let res = await adminService.getCartList("get", this.userId,business)
      this.cartId = res[0]?.cart_id;
      this.cartList = res?.map((x) => ({
        part_id: x.part_key,
        Part_Number: x.part_num,
        img: x.img,
        Description: x.part_desc,
        qty: x.qty,
        available_qty: x.available_qty,
        cart_line_key: x.cart_line_id
      }))

    },
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },
    async refreshCartList() {
      let resp = await adminService.getCartList("get", this.userId,this.bu_key);
      if (resp != "NA") {
        this.cartList = resp?.map((x) => ({
          part_id: x.part_key,
          Part_Number: x.part_num,
          img: x.img,
          Description: x.part_desc,
          qty: x.qty,
          available_qty: x.available_qty,
          cart_line_key: x.cart_line_id
        }));
        bus.$emit('cart', this.cartList);
        resp[0]?.cart_id ? this.cartId = resp[0]?.cart_id : this.cartId = "";
      }
      else {
        bus.$emit('cart', "");
        this.cartList = []
        this.goToOrders()
      }
    },
    goToOrders() {
      this.$router.push({
        name: "orderCart",
      });
    },
    async updateCart(product, passType) {
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );
      let cartlines = [{
        cart_line_key: existingCartItem ? existingCartItem.cart_line_key : 0,
        part_key: product.part_id,
        qty: product.qty
      }];

      let updateObj = {
        user_id: this.userId,
        pass: passType,
        cart_key: this.cartId,
        cartlines: cartlines
      };

      let submitObj = { json: JSON.stringify(updateObj) };
      let res;
      if (passType === 1) {
        res = await adminService.postCartUpdate("post", submitObj, true);
      } else {
        res = await adminService.postCartUpdate("post", submitObj, true);
      }

      if (res) {
        await this.refreshCartList();
      }
    },

    async modifyCart(product, actionType) {
      this.product = product
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );

      if (actionType === 'increase') {
        if (product.qty < product.available_qty) {
          product.qty++;
          if (this.cartId) {
            if (existingCartItem) {
              await this.updateCart(product, 2); // Update existing item (pass=2)
            } else {
              await this.updateCart(product, 1); // Add new item (pass=1)
            }
          }
        }
      } else if (actionType === 'decrease') {
        if (product.qty > 0) {
          if (this.cartId) {
            if (product.qty === 1) {
              this.showDialog = true;
              this.message = "Are you sure you want to remove this item from Cart?"
            } else {
              product.qty--;
              await this.updateCart(product, 2); // Update existing item (pass=2)
            }
          }
        }
      }
    },

    // Replace increaseQuantity and decreaseQuantity with the new modifyCart function
    async increaseQuantity(product, index) {
      await this.modifyCart({ ...product, index }, 'increase');
    },

    async decreaseQuantity(product, index) {
      await this.modifyCart({ ...product, index }, 'decrease');
    },
    async onClickDialogOk() {
      this.product.qty--;
      let res = await this.updateCart(this.product, 3);
      await this.refreshCartList();
      if (!res) {
        this.showDialog = false;
        this.message = ""
      }
    },

    onClickDialogCancel() {
      this.showDialog = false;
      this.message = ""
    },

    async onClickSubmitApproval() {
      console.log(this.addObj[0])
      let obj = {
        user_id: this.userId,
        cart_key: this.cartId,
        ship_key: this.address=="defaultAddress"?this.addObj[0].comp_id:this.addObjShip.comp_id,
        is_default_ship:this.address=="defaultAddress"?1:0,
        bu_key:this.bu_key,
      }
      let submitObj = { json: JSON.stringify(obj) }
      let res = await adminService.postSubmitOrder("post", submitObj, true)
      if (res == "Order created successfully!") {
        await this.refreshCartList();
        this.goToOrders()
      }


    },
    onChangeAddress(value){
      value=="addAddress"?this.showAddressComp=true:this.showAddressComp=false
    },
    closeDialog(){
      this.showAddressComp=false;
    },
    async addressSubmit(addressObj,loc){
      this.closeDialog()
       this.addObjShip=addressObj
       let obj={
        user_id: this.userId,
        pass: loc=="add"?3:4,
        bu_key:this.bu_key,
        comp_key: this.addObjShip.comp_id,
        first: this.addObjShip?.first,
        last: this.addObjShip?.last,
        email: this.addObjShip?.email,
        company: this.addObjShip?.company,
        phone1: this.addObjShip?.phone1,
        phone2: this.addObjShip?.phone2,
        address1:this.addObjShip?.address1,
        address2: this.addObjShip?.address2,
        city: this.addObjShip?.city,
        state: this.addObjShip?.state,
        zip: this.addObjShip?.zip,
        country: this.addObjShip?.country,
      }
      let addressObject={json:JSON.stringify(obj)}
      let resp= await adminService.postDefaultAddress("post",addressObject,false)
      if(resp?.Message){
        this.addObjShip.comp_id=resp?.Message
      }

    }
  },


  components: {
    breadcrumbComp,
    CommonDialog,
    AddressComp
  }
};