import { render, staticRenderFns } from "./address-comp.vue?vue&type=template&id=56bf051b&scoped=true&"
import script from "./js/address-comp.js?vue&type=script&lang=js&"
export * from "./js/address-comp.js?vue&type=script&lang=js&"
import style0 from "./address-comp.vue?vue&type=style&index=0&id=56bf051b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56bf051b",
  null
  
)

export default component.exports