import adminService from "@/olp-components/admin/js/admin-service";
import utility from "../../../shared/utility";
import encryptUtility from "../../utility/encrypt-utility";
import commonApiService from "./common-api-service";
export default {
  name: "addressComp",
  props: {
    compCalled: Boolean,
    closeDialog: Function,
  },
  data: () => ({
    zipcode: [(v) => /^\d{5}(-\d{4})?$/.test(v) || "Enter valid Postal"],
    postalCodeNonUS: [
      (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Invalid Postal Code",
    ],
    required: [(v) => !!v || "Field is required"],
    emailRule: [(v) => /.+@.+\..+/.test(v) || "Enter valid Email"],
    userId: encryptUtility.localStorageDecrypt("userID"),
    addAddress: false,
    disableAddAddress: false,
    existingAddress: false,
    disableExistingAddress: false,
    showAddressForm: false,
    existingAddressValue:"",
    showStateDrop: false,
    bu_key:encryptUtility.localStorageDecrypt("olpBusinessSelected"),
    address: {
      comp_id: 0,
      first: "",
      last: "",
      email: "",
      company: "",
      phone1: "",
      phone2: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "USA",
      
    },
    addressList:[],
    panel: 0,
    countryData: [],
    existingCompanyData:[],
    stateData: [],
  }),
  mounted() {
    this.getCountryList();
  },
  methods: {
    async countrySelected() {
      this.address.state = "";
      this.address.zip = "";
      this.$refs.addressForm?.resetValidation();
      let data1 = await commonApiService.countryListNew(
        this.userId,
        this.address.country,
        2,
        "get"
      );
      this.stateData = data1.StateInfo;
      if (this.stateData.length !== 0) {
        this.showStateDrop = true;
      } else {
        this.showStateDrop = false;
      }
    },
    async getCountryList() {
      let data = await commonApiService.countryListNew(
        this.userId,
        "",
        1,
        "get"
      );
      this.countryData = data.CountryInfo;
      this.countrySelected();
    },
    closeDialog() {
      this.compCalled = false;
      this.$emit("closeDialogBox");
    },
    onChangeAddAddress() {
      this.disableExisting = this.addAddress;
      this.showAddressForm = this.addAddress;
    },
    async onChangeExistingAddressSwitch(){
        this.disableAddAddress = this.existingAddress;
        this.existingCompanyData= await this.requestBody();
        this.addressList=this.existingCompanyData
    },
     onChangeExistingAddress(existingAddressValue) {
        this.showAddressForm=true;
        let addressFilter=this.existingCompanyData.filter(element => existingAddressValue==element.ship_id).map((x) => ({
            comp_id:x.ship_id,
            first: x.first,
            last: x.last,
            email: x.email,
            company: x.company,
            phone1: x.phone1,
            phone2: x.phone2,
            address1: x.address1,
            address2: x.address2,
            city:x.city,
            state: x.state,
            zip: x.zip,
            country: x.country
          }));
          this.address=addressFilter[0]
    },
    postalCodeFormat(event, num) {
      this.postal = utility.formatPostalCode(event, num);
    },
    onClickSubmit(loc) {
      let addressObj = this.address;
      if (this.$refs.addressForm?.validate()) {
        this.$emit("addressSubmit", addressObj,loc);
        this.compCalled = false;
      }
    },
    async requestBody(){
        let obj={
            user_id: this.userId,
            pass: 2,
            bu_key:this.bu_key,
            comp_key: 0,
            first: "",
            last:"",
            email: "",
            company: "",
            phone1: "",
            phone2: "",
            address1:"",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
          }
          let addressObject={json:JSON.stringify(obj)}
          let resp= await adminService.postDefaultAddress("post",addressObject,false)
          return resp
    },
  },
};
